<template>
  <div id="newsStoryContent">
    <p id="Text1">
      Duke Exeter thought it would be a good idea for me to chronicle the completion of Nexus Tower. So for future builders, here we go!
    </p>
    <p class="center">
      <!-- pic2FD8B8E4EC2A272DD047E50E7078B03A.jpg -->
      <img style="height: 383px; width: 400px;" class="rounded" src="@/assets/news-network/dr-overbuilds-nexus-tower-diary-1.jpg">
    </p>
    <p>
      First, you may wonder, why build a massive tower on Crux Prime- a planet which is swarming with the Maelstrom? Well, the Imagination Nexus of course! It's the original, pure source of Imagination in the Universe and it must be protected!
    </p>
    <p>
      Construction on Nexus Tower was going well, but we started to run short of bricks. Hael Storm proposed a vast collection of bricks from Minifigures across the Universe, and Duke Exeter has agreed to lead the effort! We've put out a call to all Minifigures to donate bricks and help finish Nexus Tower There is even a competition to see who can donate the most bricks!
    </p>
    <p>
      I'm a little worried about the Bank we were building in Nexus Tower. I am just not sure it is safe enough! What if Nexus Tower were under attack? We must protect everyone’s gear and deposits!!. Duke Exeter had the great idea to make the Bank into a Vault instead. What is the difference between a Bank and a Vault? Why, rocket engines, of course!
    </p>
    <p>
      Bricks have been arriving daily on the cargo ships out of Nimbus Station, and now progress on the Tower is moving at an accelerated rate! After a week of donations, redontop, Ninewells and huss1 have donated over 2,234,000 bricks combined!
    </p>
    <p>
      Check back next week for another update!
    </p>
  </div>
</template>
